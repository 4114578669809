import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchTranslations } from "../actions/languageActions";
import NewHeader from "../components/Header/NewHeader";
import Loading from "../components/loading/Loading";
import { Helmet } from "react-helmet-async";
const StudentForm = () => {
  useEffect(() => {
    // Scroll to 1000px vertically
    window.scrollTo(0, 650);
  }, []);
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  );
  const translations = useSelector(
    (state) => state.language.translations[selectedLanguage]
  );

  useEffect(() => {
    dispatch(fetchTranslations(selectedLanguage));
  }, [dispatch, selectedLanguage]);

  if (!translations) {
    return <div>
    <Loading/>
    </div>; // Show loading state while translations are being fetched
  }

  const { forms } = translations;
  return (
    <>
      <Helmet>
<title>Forms - Signet Institute | Certificate & Diploma Courses in Australia</title>
<meta name='description' content='Discover a wide range of forms & applications at Signet Institute. Submit (Online Forms) requests, applications inquiries efficiently.'data-rh="true" />
</Helmet>
      <NewHeader />
      
      <div className="pt--120">
        <div className="container">
          
          <div className="row">
          <div className="flex justify-center items-center min-h-screen">
      <iframe
        title="Online Request Form for Students"
        aria-label="Online Request Form for Students"
        frameBorder="0"
        style={{ height: "1600px", width: "99%", border: "none" }}
        src="https://forms.zohopublic.com.au/signetinstituteofaustralia/form/OnlineRequestForm/formperma/IUdQgp9NdRCpV_rse0LmGpojp89XvaYFjr0_mvmI7GY"
      ></iframe>
    </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StudentForm;
